%wagon-common {
  border: 1px solid #f1f1f1;
  font-size: 13px;
}

%ktj-train-header-content {
  justify-content: space-around;
  line-height: initial;
}

.ktj-wagon-header {
  background-color: #f7f7f7 !important;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex: 1;

  div {
    padding: 15px 0;
  }
}

.ktj-train-header {
  @extend %ktj-train-header-content;
  display: flex;
  background-color: #f7f7f7 !important;
  text-align: center;

  div {
    padding: 15px 0;
  }

  .btn-primary {
    font-size: 14px !important;
    padding: 10px !important;
    width: 90% !important;
    margin: 10px 0 !important;
  }
}

.ktj-wagons {
  @extend %wagon-common;
  div {
    padding: 5px 0;
  }
}

.ktj-modal-body {
  overflow-y: scroll;
  max-height: 30em;
  min-height: 10em;
}

.ktj-places {
  @extend %wagon-common;
  @extend %ktj-train-header-content;
  padding: 1% 20%;
}

.ktj-selectable-block {
  margin-bottom: 10px;
  border: 1px solid #fff;
  cursor: pointer;

  &:hover {
    border: 1px solid #ffcd0546;
  }

  &:focus {
    outline:0;
  }
}

.ktj-selected-block {
  border: 1px solid #ffcc05;
}

.ktj-place-info {
  display: flex;
  justify-content: space-around;

  div:first-child {
    width: 8%;
  }
}

.passenger-form {
  padding: 20px 0;
  border-bottom: 1px dashed lightgray;
  display: flex;

  input {
    margin-right: 10px;
    font-size: 13px;
    text-indent: 5px;
    min-width: 12%;

    &:focus {
      outline:0;
    }
  }

  .select-wrapper {
    margin-right: 10px;
  }
}

.passenger-form-row {
  display: flex;

  .form-group {
    min-width: 19%;
    margin: 10px 10px 0 0 !important;
  }
}

.passenger-form-col {
  &:first-of-type {
    flex: 1;
  }

  h4 {
   font-size: 16px;
   margin-top: 0;
  }
}

.ktj-field {
  margin-top: 10px;
  height: 53px;
}

.enter-passenger-header {
  h3 {
    text-align: center;
  }
}

.ktj-city {
  display: flex;
}

.selected-train-info {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  border: 1px solid lightgray;

  button {
    margin: 10px;
  }
}

// Схема вагона
.car-scheme {
  position: relative;
  width: 853px;
  height: 135px;
  margin: 0 auto;
  background-position: center center;
  background-repeat: no-repeat;

  > div {
    position: absolute;
    width: 20px;
    height: 20px;
    color: red;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    font-weight: bold;
  }

  > div.free {
    color: white;
  }

  /* Купе */
   &.coupe {
    background-image: url('../../../public/images/ktj/kupe132-853.png');
    width: 853px;
    height: 135px;

    > div:nth-of-type(2n + 1) { top: 60px; }
    > div:nth-of-type(2n + 2) { top: 30px; }
    > div:nth-of-type(-n + 38) { left: 763px; }
    > div:nth-of-type(-n + 36) { left: 738px; }
    > div:nth-of-type(-n + 34) { left: 693px; }
    > div:nth-of-type(-n + 32) { left: 670px; }
    > div:nth-of-type(-n + 30) { left: 624px; }
    > div:nth-of-type(-n + 28) { left: 600px; }
    > div:nth-of-type(-n + 26) { left: 555px; }
    > div:nth-of-type(-n + 24) { left: 531px; }
    > div:nth-of-type(-n + 22) { left: 486px; }
    > div:nth-of-type(-n + 20) { left: 462px; }
    > div:nth-of-type(-n + 18) { left: 416px; }
    > div:nth-of-type(-n + 16) { left: 393px; }
    > div:nth-of-type(-n + 14) { left: 347px; }
    > div:nth-of-type(-n + 12) { left: 324px; }
    > div:nth-of-type(-n + 10) { left: 278px; }
    > div:nth-of-type(-n + 8) { left: 255px; }
    > div:nth-of-type(-n + 6) { left: 210px; }
    > div:nth-of-type(-n + 4) { left: 187px; }
    > div:nth-of-type(-n + 2) { left: 140px; }
  }

  /* Плацкарт */
  &.platscard {
    background-image: url('../../../public/images/ktj/plac132-853.png');
    width: 853px;
    height: 135px;

    > div:nth-of-type(2n + 1) { top: 60px; }
    > div:nth-of-type(2n + 2) { top: 30px; }
    > div:nth-of-type(n + 37) { top: 100px; }
    > div:nth-of-type(-n + 38) { left: 763px; }
    > div:nth-of-type(-n + 36) { left: 738px; }
    > div:nth-of-type(-n + 34) { left: 693px; }
    > div:nth-of-type(-n + 32) { left: 670px; }
    > div:nth-of-type(-n + 30) { left: 624px; }
    > div:nth-of-type(-n + 28) { left: 600px; }
    > div:nth-of-type(-n + 26) { left: 555px; }
    > div:nth-of-type(-n + 24) { left: 531px; }
    > div:nth-of-type(-n + 22) { left: 486px; }
    > div:nth-of-type(-n + 20) { left: 462px; }
    > div:nth-of-type(-n + 18) { left: 416px; }
    > div:nth-of-type(-n + 16) { left: 393px; }
    > div:nth-of-type(-n + 14) { left: 347px; }
    > div:nth-of-type(-n + 12) { left: 324px; }
    > div:nth-of-type(-n + 10) { left: 278px; }
    > div:nth-of-type(-n + 8) { left: 255px; }
    > div:nth-of-type(-n + 6) { left: 210px; }
    > div:nth-of-type(-n + 4) { left: 187px; }
    > div:nth-of-type(-n + 2) { left: 140px; }
    > div:nth-of-type(37) { left: 735px; }
    > div:nth-of-type(38) { left: 696px; }
    > div:nth-of-type(39) { left: 666px; }
    > div:nth-of-type(40) { left: 626px; }
    > div:nth-of-type(41) { left: 597px; }
    > div:nth-of-type(42) { left: 557px; }
    > div:nth-of-type(43) { left: 528px; }
    > div:nth-of-type(44) { left: 489px; }
    > div:nth-of-type(45) { left: 459px; }
    > div:nth-of-type(46) { left: 420px; }
    > div:nth-of-type(47) { left: 390px; }
    > div:nth-of-type(48) { left: 351px; }
    > div:nth-of-type(49) { left: 321px; }
    > div:nth-of-type(50) { left: 282px; }
    > div:nth-of-type(51) { left: 253px; }
    > div:nth-of-type(52) { left: 213px; }
    > div:nth-of-type(53) { left: 183px; }
    > div:nth-of-type(54) { left: 144px; }
  }

  /* Общий */
  &.common {
    background-image: url('../../../public/images/ktj/plac132-853.png');
    width: 853px;
    height: 135px;

    > div:nth-of-type(3n + 3) { top: 20px; }
    > div:nth-of-type(3n + 2) { top: 40px; }
    > div:nth-of-type(3n + 1) { top: 60px; }
    > div:nth-of-type(n + 55) { top: 100px; }
    > div:nth-of-type(-n + 57) { left: 763px; }
    > div:nth-of-type(-n + 54) { left: 738px; }
    > div:nth-of-type(-n + 51) { left: 693px; }
    > div:nth-of-type(-n + 48) { left: 670px; }
    > div:nth-of-type(-n + 45) { left: 624px; }
    > div:nth-of-type(-n + 42) { left: 600px; }
    > div:nth-of-type(-n + 39) { left: 555px; }
    > div:nth-of-type(-n + 36) { left: 531px; }
    > div:nth-of-type(-n + 33) { left: 486px; }
    > div:nth-of-type(-n + 30) { left: 462px; }
    > div:nth-of-type(-n + 27) { left: 416px; }
    > div:nth-of-type(-n + 24) { left: 393px; }
    > div:nth-of-type(-n + 21) { left: 347px; }
    > div:nth-of-type(-n + 18) { left: 324px; }
    > div:nth-of-type(-n + 15) { left: 278px; }
    > div:nth-of-type(-n + 12) { left: 255px; }
    > div:nth-of-type(-n + 9) { left: 210px; }
    > div:nth-of-type(-n + 6) { left: 187px; }
    > div:nth-of-type(-n + 3) { left: 140px; }
    > div:nth-of-type(55) { left: 735px; }
    > div:nth-of-type(56) { left: 715px; }
    > div:nth-of-type(57) { left: 696px; }
    > div:nth-of-type(58) { left: 666px; }
    > div:nth-of-type(59) { left: 646px; }
    > div:nth-of-type(60) { left: 626px; }
    > div:nth-of-type(61) { left: 597px; }
    > div:nth-of-type(62) { left: 577px; }
    > div:nth-of-type(63) { left: 557px; }
    > div:nth-of-type(64) { left: 528px; }
    > div:nth-of-type(65) { left: 508px; }
    > div:nth-of-type(66) { left: 489px; }
    > div:nth-of-type(67) { left: 459px; }
    > div:nth-of-type(68) { left: 439px; }
    > div:nth-of-type(69) { left: 420px; }
    > div:nth-of-type(70) { left: 390px; }
    > div:nth-of-type(71) { left: 370px; }
    > div:nth-of-type(72) { left: 351px; }
    > div:nth-of-type(73) { left: 321px; }
    > div:nth-of-type(74) { left: 301px; }
    > div:nth-of-type(75) { left: 282px; }
    > div:nth-of-type(76) { left: 253px; }
    > div:nth-of-type(77) { left: 233px; }
    > div:nth-of-type(78) { left: 213px; }
    > div:nth-of-type(79) { left: 183px; }
    > div:nth-of-type(80) { left: 163px; }
    > div:nth-of-type(81) { left: 144px; }
  }

  /* Люкс */
  &.luxury {
    background-image: url('../../../public/images/ktj/kupe132-853.png');
    width: 853px;
    height: 135px;

    > div { top: 45px; }
    > div:nth-of-type(18) { left: 738px; }
    > div:nth-of-type(17) { left: 693px; }
    > div:nth-of-type(16) { left: 670px; }
    > div:nth-of-type(15) { left: 624px; }
    > div:nth-of-type(14) { left: 600px; }
    > div:nth-of-type(13) { left: 555px; }
    > div:nth-of-type(12) { left: 531px; }
    > div:nth-of-type(11) { left: 486px; }
    > div:nth-of-type(10) { left: 462px; }
    > div:nth-of-type(9) { left: 416px; }
    > div:nth-of-type(8) { left: 393px; }
    > div:nth-of-type(7) { left: 347px; }
    > div:nth-of-type(6) { left: 324px; }
    > div:nth-of-type(5) { left: 278px; }
    > div:nth-of-type(4) { left: 255px; }
    > div:nth-of-type(3) { left: 210px; }
    > div:nth-of-type(2) { left: 187px; }
    > div:nth-of-type(1) { left: 140px; }
  }

  /* Мягкий */
  &.soft {
    background-image: url('../../../public/images/ktj/gentle.jpg');
    width: 853px;
    height: 135px;

    > div:nth-of-type(-n + 10),
    > div:nth-of-type(10n + 17),
    > div:nth-of-type(10n + 18),
    > div:nth-of-type(10n + 19),
    > div:nth-of-type(10n + 20) { display: none; }
    > div:nth-of-type(10n + 15),
    > div:nth-of-type(10n + 16) { top: 21px; }
    > div:nth-of-type(10n + 13),
    > div:nth-of-type(10n + 14) { top: 43px; }
    > div:nth-of-type(10n + 11),
    > div:nth-of-type(10n + 12) { top: 66px; }
    > div:nth-of-type(11),
    > div:nth-of-type(13),
    > div:nth-of-type(15) { left: 177px; }
    > div:nth-of-type(12),
    > div:nth-of-type(14),
    > div:nth-of-type(16) { left: 239px; }
    > div:nth-of-type(21),
    > div:nth-of-type(23),
    > div:nth-of-type(25) { left: 301px; }
    > div:nth-of-type(22),
    > div:nth-of-type(24),
    > div:nth-of-type(26) { left: 363px; }
    > div:nth-of-type(31),
    > div:nth-of-type(33),
    > div:nth-of-type(35) { left: 426px; }
    > div:nth-of-type(32),
    > div:nth-of-type(34),
    > div:nth-of-type(36) { left: 488px; }
    > div:nth-of-type(41),
    > div:nth-of-type(43),
    > div:nth-of-type(45) { left: 550px; }
    > div:nth-of-type(42),
    > div:nth-of-type(44),
    > div:nth-of-type(46) { left: 612px; }
    > div:nth-of-type(51),
    > div:nth-of-type(53),
    > div:nth-of-type(55) { left: 675px; }
    > div:nth-of-type(52),
    > div:nth-of-type(54),
    > div:nth-of-type(56) { left: 737px; }
  }

  /* Тальго купе */
  &.talgo {
    background-image: url('../../../public/images/ktj/talgo_turist_class1_short.jpg');
    width: 853px;
    height: 135px;

    > div:nth-of-type(2n) { top: 9px; }
    > div:nth-of-type(2n + 1) { top: 54px; }
    > div:nth-of-type(-n + 18) { left: 568px; }
    > div:nth-of-type(-n + 16) { left: 541px; }
    > div:nth-of-type(-n + 14) { left: 475px; }
    > div:nth-of-type(-n + 12) { left: 448px; }
    > div:nth-of-type(-n + 10) { left: 382px; }
    > div:nth-of-type(-n + 8) { left: 355px; }
    > div:nth-of-type(-n + 6) { left: 288px; }
    > div:nth-of-type(-n + 4) { left: 262px; }
    > div:nth-of-type(-n + 2) { left: 195px; }
  }

  /* Тальго гранд класс */
  &.talgo-grand-class {
    background-image: url('../../../public/images/ktj/talgo_gran_class.png');
    width: 853px;
    height: 135px;

    > div:nth-of-type(2n) { top: 9px; }
    > div:nth-of-type(2n + 1) { top: 54px; }
    > div:nth-of-type(-n + 10) { left: 550px; }
    > div:nth-of-type(-n + 8) { left: 527px; }
    > div:nth-of-type(-n + 6) { left: 373px; }
    > div:nth-of-type(-n + 4) { left: 350px; }
    > div:nth-of-type(-n + 2) { left: 196px; }
  }

  /* Тальго бис */
  &.talgo-biz {
    background-image: url('../../../public/images/ktj/talgo_biz_class.png');
    width: 853px;
    height: 135px;

    > div:nth-of-type(2n) { top: 9px; }
    > div:nth-of-type(2n + 1) { top: 54px; }
    > div:nth-of-type(-n + 12) { left: 634px; }
    > div:nth-of-type(-n + 10) { left: 505px; }
    > div:nth-of-type(-n + 8) { left: 480px; }
    > div:nth-of-type(-n + 6) { left: 350px; }
    > div:nth-of-type(-n + 4) { left: 324px; }
    > div:nth-of-type(-n + 2) { left: 195px; }
  }

  /* Трёхярусный */
  &.three-storey {
    background-image: url('../../../public/images/ktj/kupe_new_long_40mest3.png');
    width: 853px;
    height: 135px;

    > div:nth-of-type(3n) { top: 19px; }
    > div:nth-of-type(3n + 2) { top: 41px; }
    > div:nth-of-type(3n + 1) { top: 63px; }
    > div:nth-of-type(-n + 60) { left: 743px; }
    > div:nth-of-type(-n + 57) { left: 701px; }
    > div:nth-of-type(-n + 54) { left: 680px; }
    > div:nth-of-type(-n + 51) { left: 638px; }
    > div:nth-of-type(-n + 48) { left: 616px; }
    > div:nth-of-type(-n + 45) { left: 574px; }
    > div:nth-of-type(-n + 42) { left: 553px; }
    > div:nth-of-type(-n + 39) { left: 512px; }
    > div:nth-of-type(-n + 36) { left: 490px; }
    > div:nth-of-type(-n + 33) { left: 447px; }
    > div:nth-of-type(-n + 30) { left: 426px; }
    > div:nth-of-type(-n + 27) { left: 384px; }
    > div:nth-of-type(-n + 24) { left: 363px; }
    > div:nth-of-type(-n + 21) { left: 320px; }
    > div:nth-of-type(-n + 18) { left: 300px; }
    > div:nth-of-type(-n + 15) { left: 256px; }
    > div:nth-of-type(-n + 12) { left: 236px; }
    > div:nth-of-type(-n + 9) { left: 193px; }
    > div:nth-of-type(-n + 6) { left: 172px; }
    > div:nth-of-type(-n + 3) { left: 130px; }
  }
}

.ktj-passenger-memo {
  margin: 20px 0 0 0;
  border: 1px solid lightgray;
  padding: 10px;
  display: flex;
  justify-content: space-around;
}

.ktj-places-info {
  margin-top: 20px 0 0 0;
  text-align: center;

  .button-group {
    padding: 10px;
    text-align: center;
  }
}

.ktj-enter-places {
  display: flex;
  justify-content: space-evenly;

  input {
    min-height: 30px;
    margin-left: 5px;
  }
}


.checkbox-button-group input{
  display: none;
}

.checkbox-button-group{
  display: flex;
  margin-top: 15px;
  span{
    display: flex;
  }
  :first-child label{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  :last-child label{
       border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid #d9d9d9;
  }
}

.checkbox-button {
  border: 1px solid #d9d9d9;
  border-right: none;
    background: #ffffff;
    outline: none;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
    font-size: 11px;
    padding: 0 15px;
    font-weight: 400;
    text-align: center;
    display: flex;
    min-height: 50px;
    align-items: center;

}
.title-checkboxes{
  margin-top: 15px;
  font-size: 14px;
}

.ktj-delete-btn{
  background: #e6e6e6;
}

.select-document{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.checkbox-button-tarif input:checked + label,
.checkbox-button-tarif input:checked + label:active {
  background-color: #ffcc05;
}

.checkbox-button-group input:checked + label,
.checkbox-button-group input:checked + label:active {
  background-color: #ffcc05;
}

.ktj-booking-info {
  h2 {
    text-align: center;
  }
}

.passengers-confirm-form-header {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0 20px;

  > div span {
    font-weight: bold;
  }
}