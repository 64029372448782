.identification-block {
  position: fixed;
  bottom: 0;
  right: 324px;
  width: 15%;
  z-index: 1;
}

.form-data input {
  width: 100%;
  height: 50px;
}

.identification-block-title {
  color: rgba(58, 62, 70, 1);
  background: linear-gradient(0deg, #FFDE16 0%, #FFF229 100%);
  border-radius: 4px 4px 0px 0px;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  outline: none;
  user-select: none;
  padding: 0 10px;
  border:0;
}

.form-data {
  display: flex;
  flex-direction: column;

  div {
    flex: 1;

    input {
      width: 98%;
      font-size: 14px;
      padding: 5px 0 5px 15px;
      border-radius: 2px;
      border: 1px solid #d1d1d0;
    }
  }
}

.image-uploader {
  display: flex;
  border: 1px dashed lightgray;
  margin-top: 15px;
  border-radius: 3px;

  .image-uploader-item {
    min-height: 210px;
    height: 210px;
    flex: 1;
  }

  .fileContainer {
    padding: 0;

    .uploadPictureContainer {
      width: 50%;
      background: none;
      box-shadow: none;
      border: 1px dashed #f2f2f2;
    }

    .deleteImage {
      background: #fff;
      border: 1px solid #f44336;
      color: #f44336;
      font-size: 14px;
      font-weight: 300;
      top: -15px;
      right: -15px;
    }

    box-shadow: none;
    text-align: center;

    p {
      height: 40px;

      svg {
        margin-top: 10px;
      }
    }

    .chooseFileButton {
      color: #1976d2;
      border: 1px solid rgba(25, 118, 210, 0.5);
      background: #fff;
    }
  }
}

.image-uploader-error {
  border: 1px dashed #f44336;
}

.image-preview {
  text-align: center;
  padding: 20px 0 0 0;
}