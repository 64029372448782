.checkbox-custom {
  user-select: none;
  cursor: pointer;
  font-size: 8px !important;
}
.checkbox-label {
  outline: none;
  margin-left: 10px;
  font-size: 12px;
}
.checkbox-icon {
  fill: #ffcc05;
  stroke: #fff;
}

.checkbox-container {
  display: inline-block;
  vertical-align: middle;
}
.checkbox-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox-styled {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  ::after {
    position: absolute;
    top: 50%;
    left: 22%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    -ms-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: " ";
  }
}
