
.MenuItem {
  font-size:12px!important;
  padding:10px!important;
  font-family: -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
  white-space: normal!important;
}
.MenuList ul {padding: 0!important;}
.MenuItem:hover {background-color: #eee!important;}
.notifSumm {font-weight: bold;margin:20px 0;font-size:18px}
.notifTitle {font-weight: bold;font-size:17px}
.notifTime {
  background-color: rgb(112, 112, 112);
  color:#fff;
  border-radius: 5px;
  width:150px;
  padding: 10px;
  margin:0 auto 20px auto;
}
.notifModal .modal-header {border:0;padding:0}
.notifModal .modal-wrapper, .notifModal .modal-body {overflow: hidden;}
.notifModalCont {text-align: center;}
.notifButtons {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2,150px);
  grid-gap:30px;
  margin:30px 0 0 0}

.notifButton {
  border: none;
  background: linear-gradient(0deg, #FFDE16 0%, #FFF229 100%);
  color: #333;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  height: 54px;
  width: 100%;
  max-width: 150px;
  margin:0 auto
}
.cancelButton {
  background: linear-gradient(0deg, #992907 0%, #c53003 100%);
  color:#fff;
}
.notifButton:hover{
  box-shadow: 0 3px 10px rgba(0,0,0,.3);
}

.pulse {
  margin:100px;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #FF3131;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(224, 61, 33, 0.4);
  animation: pulse 3s infinite;
  padding: 0!important;
}
.pulse button {background-color: transparent;}
.pulse:hover {
  animation: none;
}
.pulse svg {
  fill: #FF3131;
}
.pulse-disable svg {
  fill: grey;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(224, 61, 33, 0.4);
    transform: scale(.9,.9);
  }
  70% {
      -webkit-box-shadow: 0 0 0 15px rgba(224, 61, 33, 0.4);
      transform: scale(1.1,1.1);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(224, 61, 33, 0.4);
     transform: scale(.9,.9);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(224, 61, 33, 0.4);
    box-shadow: 0 0 0 0 rgba(224, 61, 33, 0.4);
    transform: scale(.9,.9);
  }
  70% {
      -moz-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      transform: scale(1.1,1.1);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      transform: scale(.9,.9);
  }
}