.inputWrapper {
  position: relative;
  .input-field {
    width: 100%;
    font-size: 14px;
    padding: 5px 0 5px 15px;
    border-radius: 2px;
    border: 1px solid #d1d1d0;

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.input-search {
  background-image: url(../../../public/images/seacrc-icon.svg);
  background-repeat: no-repeat;
  background-position: right;
}

.auth-input {
  width: 300px !important;
  &::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
}

.input-field .input-predix {
  padding: 5px 0 5px 25px !important;
}
.input-warning {
  border: 1px solid #ca4040 !important;
}

.labelsWrapper {
  span {
    font-size: 14px;
  }
}

.inputError {
  font-size: 12px;
  color: #ca4040;
  display: block;
}
