.accordion__section {
  display: flex;
  flex-direction: column;
}

.accordion {
  background: #ffffff;
  border: none;
  border-radius: 5px;
  color: #444;
  cursor: pointer;
  padding: 5px 18px;
  display: flex;
  align-items: center;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion_active{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
  background: #f2f2f2;
  border-color: #f2f2f2;
}

.accordion__title {
  font-size: 15px;
  margin: 0;
}

.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
  transform: rotate(90deg);
}

.rotate {
  transform: rotate(0);
}

.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: all 0.3s ease;
  max-height: 0;
  opacity: 0;
}

.accordion_display{
  max-height: 100%;
  opacity: 1;

}
.accordion__text {
  padding: 10px;
}
