.pay-counter-icon {
  margin-left: 10px;
  transform: rotate(270deg);
  fill: #F1E23C
}

.pay-counter-rotate {
  transform: rotate(90deg);
}

.pay-counter-wrapper {
  position: fixed;
  bottom: 0;
  right: 24px;
  width: 20%;
}

.pay-counter-block {
  background: #fff;
  padding: 28px 15px 15px 20px;
  width: 100%;
  display: none;
  flex-flow: column;
  align-items: flex-start;
  transition: all 0.3s ease;
  opacity: 1;
}

.pay-counter-block-display {
  display: flex;
}

.pay-counter-title {
  color: #F1E23C;
  background: #333333;
  border-radius: 4px 4px 0px 0px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  user-select: none;
  padding: 0 10px;
  border:0;
  width:100%
}

.pay-counter-button {
  margin: 16px 0 0 0;
  width: 100%;
  border: 0;
}
