.custom-button {
  border: 1px solid rgb(217, 217, 217);
  background: #ffffff;
  outline: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  font-size: 13px;
  padding: 0 15px;
  height: 32px;
  // border: 1px solid transparent;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  margin-left: 1px;
}

.custom-button:hover {
  background-color: #F8F8F8;
}

.custom-button-background {
  background: linear-gradient(0deg, #FFDE16 0%, #FFF229 100%);
}

.button-refresh {
  display: none;
  align-items: center;
  background: #d8534e;
  color: #ffffff;
}

.button-display-warning {
  display: flex;
}
