.steps {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  white-space:nowrap; 
  text-align:center;
}

.step {
  display: flex;

  &:before,
  &:after {
    content:'';
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  &:after {
    width: 15em;
    margin: 0 0 0.7em 0.7em;
  }

  &:first-of-type:before,
  &:last-of-type:after {
    display:none;
  }
}

.step-active {
  color: rgba(0, 0, 0, 0.85);
}

.success-step-icon {
  margin-right: 8px;
}

.completed-step {
  color: rgba(0, 0, 0, 0.65);

  &:after {
    border-bottom: 1px solid #ffcc05 !important;
  }
}

.step-in-progress, .waiting-step {
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
  margin-right: 8px;
}

.step-in-progress {
  color: white;
  background-color: #ffcc05;
}

.waiting-step {
  color: rgba(0, 0, 0, 0.45);
  border: 1px solid rgba(0, 0, 0, 0.45);
}

.step-content {
  display: inline-block;
  vertical-align: top;  
  line-height: 1.5715;
}