.input-dropdown {
  position: relative;
}

.dropdown-list-overflow {
  max-height: 162px;
  overflow-y: scroll;
}

.input-dropdown-list {
  position: fixed;
  top: auto;
  margin-right: 10px;
  padding: 5px;
  background-color: white;
  border: 1px solid #d1d1d0;
  width: 97%;
  max-width: 260px;
  text-transform: uppercase;
  z-index: 1;
  border-radius: 5px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 9px 0px #d9d9d9;

  &>div {
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    padding: 5px;
    margin-right: 0 !important;
    outline: none;
  }

  &>div:hover {
    background: #DEDEDE;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.fiscal-modal-section {
  .input-dropdown-list {
    box-shadow: none;
  }
}



