.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #ffffff;
  padding: 0 24px 0 25px;
  height: 70px;
  box-shadow: 0px 8px 20px rgba(173, 183, 212, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-amount-wrapper {
  display: flex;
  flex-flow: column;
  padding: 0 5px;
}

.header-payment-balance {
  display: inline-flex;
}

.header-button-group {
  display: flex;
  :first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-style: none;
  }

  :last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-style: none;
  }
}

.header-devider {
  border-left: 1px solid rgba(29, 30, 34, 0.2);
  margin: 0 15px;
}

.header-search {
  width: 310px;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 36px;
  padding: 10px;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: 0;
  position: absolute;
}

.react-autosuggest__suggestions-container {
  display: none;
  top: 0;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  margin-top: -16px;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  z-index: 2;
  background-clip: padding-box;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px;
}
.logo-block {
  cursor:pointer
}