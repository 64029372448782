.loader-spin{
  margin: 30px auto 10px auto;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #555;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  z-index: 9999;
}
.loader-content {
  background-color: #232323;
  max-width: 350px;
  width: 100%;
  height: auto;
  border-radius: 5px;
  color:#FFFFFF;
  text-align: center;
  font-size: 16px;
  padding-bottom: 30px ;
}
.loader-black-content {
  padding: 0 10px;
}
.loader-header {
  background-color: #FF4A4A;
  font-size: 20px;
  padding: 10px 0;
  width: 100%;
  margin-bottom:15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.loader-wrapper{
  display: flex;
  flex-flow: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.72);
  z-index: 99999;
  justify-content: center;

}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
