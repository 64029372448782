.fiscal-modal-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;


  &:nth-of-type(2) {
     flex-direction: column;
  }

  &>div {
    margin-right: 10px;

    svg.select__icon {
      right: 20px;
    }

    .payment-method {
      padding: 0 !important;

      .radio {
        margin-right: 10px;
      }

      form {
        display: flex;
      }
    }

    .inputError {
      height: 20px;
    }
  }

  input {
    font-size: 14px;
    padding: 5px 0 5px 15px;
    border-radius: 2px;
    border: 1px solid #d1d1d0;
  }
}

.fiscal-modal-section div.firstItem, .itemSection, .itemUnits { flex: 2;}
.itemSection {padding: 5px;}
.itemUnits {max-width: 261px;}

.fiscalItem { flex: 1 ;display: grid;margin-right: 10px;}
.fiscalItem .inputWrapper .input-field{height:38px}

.fiscal-income{
  display: flex;
  flex-flow: column;
  align-items: baseline;
  .inputWrapper{
    width: 100%;
  }
  .custom-button{
    margin-top: 15px;
  }
}
.fiscal-modal-block {
  &:nth-last-of-type(1),
  &:nth-last-of-type(2) {
    display: flex;
  }
}

.fiscal-report,
.jysan-oferta {
  text-align: center;

  .head {
    display: flex;
    .title {
      flex: 1;
    }
  }

  .header-block {
    margin-bottom: 0;
    background-color: #f2f2f2;
    border: none;
  }

  thead {
    td {
      font-weight: 700;
    }
  }

  .fiscal-report-info {
    display: flex;

    .card {
      tr {
        td {
          &:first-of-type {
            font-weight: 700;
          }
        }
      }
    }

    &>div {
      width: 100%;
      height: 100%;
      text-align: left;
      border: 1px solid #f2f2f2;
      margin: 20px 20px 20px 0;
      border-radius: 5px;

      &>div {
        padding: 12px 20px;
      }
    }

    h5 {
      &:first-of-type {
        margin-top: 0px;
      }
      margin-top: 20px;
      border-bottom: 1px solid #dee2e6!important;
    }

    .issues-receipt {
      table {
        font-size: 12px;

        td {
          &:first-of-type {
            font-weight: 700;
          }
        }
      }
    }
  }

  .fiscal-operations {
    margin: 20px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;

    table {
      width: 100%;
      padding: 0 20px;
    }

    &>div {
        padding: 12px 0;
      }
  }
}

.jysan-oferta {
  text-align: left;
}

@media print {
  .hideForPrint {
    display: none;
  }
}

.fiscal-receipt-confirm-block {
  display: flex;

  .fiscal-receipt-warning {
    flex: 1;
  }

  .fiscal-repeipt-preview {
    text-align: center;
    flex: 1;
  }
}