
.fiscal-tariffs {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll;

  .logo-kassa24 {
    width: 104px;
    height: 33px;
  }

  .header,
  .fiscal-tariffs-items,
  .footer-block {
    background-color: white;
    padding: 30px 60px 0 60px;
  }

  .gray {
    color: #665A5D;
  }

  .header {
    color: #231F20;

    .gray {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.5px;
      text-align: center;
    }
  }

  .fiscal-tariffs-body {
    margin-top: 70px;
    position: relative;
    width: 59%;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.5px;
      padding-top: 23px;
    }

    svg {
      width: 16px;
      height: 16px;
      color: #231F20;
    }

    .modal-close {
      top: 22px;
      right: 50px;
    }
  }

  .tariffs-block{
    margin: 0 5px 5px 5px;
    flex-basis:20%;
    border:0;
    background: transparent;
  }

  .fiscal-tariffs-items {
    display: flex;
    font-size: 12px;
    color: #231F20;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0.5px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;

    .price {
      font-size: 22px;
      font-weight: bold;
      line-height: 29px;
      letter-spacing: -0.19px;
      margin-bottom: 12px;
    }

    .description {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
    }

    .price-per-month {
      font-size: 13px;
      line-height: 15px;
      display: flex;
      align-items: center;
    }

    &>div {
      outline: none;
      cursor: pointer;
      border: 0.5px solid rgba(29, 30, 34, 0.2);
      border-top: 4px solid #665A5D;
      // margin-right: 25px;
      padding: 16px 24px;
      // flex-shrink: 1;

      &:first-of-type.active-tariff {
        border: 0.5px solid #665A5D;
        border-top: 4px solid #665A5D;
      }

      &:nth-of-type(2).active-tariff {
        border: 0.5px solid #FFF229;
        border-top: 4px solid #FFF229;
      }

      &:nth-of-type(3).active-tariff {
        border: 0.5px solid #FFCD03;
        border-top: 4px solid #FFCD03;
      }

      &:nth-of-type(4).active-tariff {
        border: 0.5px solid #EB9C00;
        border-top: 4px solid #EB9C00;
      }

      &:first-of-type {
        border-top: 4px solid #665A5D;
      }

      &:nth-of-type(2) {
        border-top: 4px solid #FFF229;
      }

       &:nth-of-type(3) {
        border-top: 4px solid #FFCD03;
      }

       &:nth-of-type(4) {
        border-top: 4px solid #EB9C00;
      }
    }

    &>div:hover {
      box-shadow: 0 1px 9px 0 rgba(0,0,0,.39);
    }
  }

  .footer-block {
    padding-bottom: 30px;
    text-align: center;
    color: #231F20;
    line-height: 100%;

    a {
      text-decoration: none;
      font-size: 14px;
      display: block;
      padding: 20px;
    }

    button {
      background: linear-gradient(0deg, #FFDE16 0%, #FFF229 100%);
      box-shadow: 0px 1px 0px #FFD60B;
      border-radius: 4px;
      width: 180px;
      height: 40px;
      border: none;
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
