.alert-wrapper{
  display: flex;
  position: relative;
  align-items: center;
  font-size: 14px;
  padding: 8px 15px 8px 38px;
  background-color: #fffbe6;
  :first-child{
    margin-right: 10px;
  }

}

.alert-icon{
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 16px;
}
