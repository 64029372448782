.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll;
}

.modal-big-size {
  width: 90% !important;
}

.modal-payment-size {
  width: 30% !important;
  margin-top: 50px !important;

  .modal-body {
    max-height: 600px;
    overflow-y: scroll;
  }
}

.modal-high-size {
  width: 70% !important;
  margin-top: 20px !important;

  .modal-body {
    max-height: 600px;
    overflow-y: scroll;
  }
}

.confirm-button-group{
  margin-top: 20px;
}

.modal-confirm-header{
  text-align: center;
}

.modal-full-height-size {
  width: 60% !important;
  margin-top: 15px !important;

  .modal-body {
    height: 80vh;
    max-height: 90vh;
    overflow-y: scroll;
  }

  .modal-scroll-body {
    height: 500px;
  }
}

.modal-confirm{
  z-index: 9999999999;
  position: absolute;
  transform: translateX(50%);
  right: 50%;
  width: 400px;
    margin-top: 100px;
    background: #fff;
    border-radius: 4px;
    padding: 32px 32px 24px;
    display: flex;
    align-items: center;
    flex-flow: column;
    border: 1px solid #828282;
}

.modal-small-size {
  width: 30% !important;
  margin-top: 200px !important;
}

.modal-small-and-high-size {
  width: 30% !important;
  max-width: 30% !important;
  margin-top: 100px !important;
}

.modal-small-and-all-high-size {
  width: 30% !important;
  margin-top: 10px !important;

  .modal-body {
    max-height: 550px !important;
  }
}

.modal-block {
  width: 60%;
  margin-top: 100px;
  background: #fff;
  border-radius: 4px;
}

.modal-block .form-data {
  padding: 20px 0 0 0;
}

.modal-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}

.modal-header-text {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  max-height: 450px;
  overflow-y: scroll;

  .pay-form {
    margin: 0;
  }

  .form-group {
    display: flex;
    flex-flow: column;
  }
}

.modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}

.modal-close {
  position: absolute;

  width: 47px;
  height: 47px;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  svg {
    display: block;
    margin: 0 auto;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
}

.modal-cancel {
  margin-right: 8px;
}

.modal-ok {
  color: #231F20 !important;
  font-weight: 500;
  border: none;
}
.modal-ok:disabled {
  cursor: auto;
  opacity:0.6
}

.confirm-modal {
  font-size: 14px;
  .modal-header-text {
    text-align: center;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.pin-code-modal {
  margin-top: 200px;
}

.pin-code-modal-body {
  text-align: center;
}

.modal-scroll-body {
  overflow-y: scroll;
  padding: 0 0 20px 0;
  height: 410px;
}