.dublicate-warning-header {
    text-align: center;
  }

.dublicate-warning-body-title {
  text-align: center;
}

.dublicate-warning-body-title,
.dublicate-warning-body {
  font-size: 14px;
  line-height: 22px;
  margin: 20px;
}