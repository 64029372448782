.dropdown-list {
  display: none;
  flex-flow: column;
  position: absolute;
  background: white;
  z-index: 999;
  border: 1px solid d9d9d9;
  border-radius: 5px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 9px 0px rgba(217, 217, 217, 1);
  :first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.dropdown-wrapper {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  &:hover {
    .dropdown-list {
      display: flex;
      transform: translateY(0);
    }
  }
}

.dropdown-new {
  display: inline-block;
  background-color: #ffcc05;
  color: #fff;
  margin-left: 10px;
  border-radius: 5px;
  padding: 3px 4px;
  font-size: 10px;
}

.dropdown-item {
  padding: 5px 12px;
  cursor: pointer;
  font-weight: 400;
  white-space: nowrap;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  &:hover {
    background: #DEDEDE;
  }

  .dropdown-new {
    display: inline;
  }

  transition: all 0.3s;
}

.dropdown-icon {
  height: 11px;
  width: 11px;
  margin-left: 10px;
}

.dropdown-exit{
  border-top:1px solid #d9d9d9!important;
}
