.alert-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll;
  text-align: center;
}

.alert-button {
  border: none;
  background: linear-gradient(0deg, #FFDE16 0%, #FFF229 100%);
  color: #333333;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  max-width: 420px;
  height: 54px;
  width: 100%;
}

.alert-button:hover {
  cursor: pointer;
}

.alert-modal-block {
  width: 580px;
  min-height: 400px;
  margin-top: 100px;
  background: #fff;
  border-radius: 4px;
  padding: 32px 32px 24px;
}

.cashout-alert-modal-block {
  padding: 52px 32px 24px;
}

.alert-header {
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  padding: 5px 70px;
}

.alert-body {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin: 30px 20px 60px 20px;
}

.alert-error {
  height: 22px;
  width: 22px;
  color: #f5222d;
  margin-right: 15px;
}
