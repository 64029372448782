.radio-input:checked,
.radio-input:not(:checked) {
  position: absolute;
  left: -9999px;
}
.radio-input:checked + label,
.radio-input:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.radio-group-wrapper {
  outline: none;
}
.radio-input:checked + label:before,
.radio-input:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ffcc05;
  border-radius: 100%;
  background: #fff;
}
.radio-input:checked + label:after,
.radio-input:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #ffcc05;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.radio-input:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.radio-input:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
