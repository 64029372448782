.loader-component{
  border: 5px solid #f3f3f3;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  z-index: 9999;

}
.loader-wrapper{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.72);
  z-index: 99999;
  h3{
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    color: #FFFFFF;
  }
  span{
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
