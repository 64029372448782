.bookmark-wrapper {
  background: transparent;
  padding: 0;
  margin: 0;
  display: inline-flex;
  border: none;
  cursor: pointer;
  outline: none;
  svg {
    width: 22px;
    height: 22px;
  }
}

.bookmark-fill {
  fill: #ffcc05 !important;
}
