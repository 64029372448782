.receipt-settings {
  display: flex;

  div {
    flex: 1;
  }

  div.check-preview {
    flex: 2;
    display: flex;
  }

  .check-preview {
    .receipt-preview {
      padding: 20px;
      min-height: 400px;
      vertical-align: middle;
      border: 1px dashed darkgray;
    }
    .receipt-preview-block {
      width: 300px;
      text-align: center;
    }
    text-align: center;
  }
}

hr {
  margin-right: 10px;
  border-top: 1px solid gray;
}

.fiscalItem {
  padding: 5px;
}
