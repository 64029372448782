@font-face {
  font-family: "FontForPasswords";
  font-style: normal;
  font-weight: 400;
  src: url("../../public/fonts/FontForPasswords.ttf");
}

#password {
  font-family: "FontForPasswords", Helvetica, Arial, sans-serif  !important;
  font-size: 4px;
  padding-top: 8px;
}

.auth-form {
  background: #f7f7f7;
  position: relative;
  width: 400px;
  padding: 24px;
  margin: 10% auto;
  border-radius: 4px;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.72);
  z-index: 1000;
  padding-top: 15%;
}
.loader h3,
.loader span,
.white-color {
  color: #ffffff;
}
.loader svg {
  color: #ffffff !important;
}
.loader h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
}
.loader span {
  font-size: 15px;
  line-height: 24px;
}
.loader div {
  text-align: center;
}
.corporate {
  border: 1px solid #03aeef;
  color: #7ed3f7;
}


#loader {
  width: 80px;
  height: 85px;
  position: relative;
  background: url(../../public/images/kassa24/kassa24.svg);
  background-size: 90px;
  margin: auto;
  right: 0px;
}
.menu-lang {
  display: none;
}



@media screen and (max-width: 375px) {
  .auth-form {
    background: #f7f7f7;
    position: relative;
    width: 100%;
    /* height: 300px; */
    padding: 24px;
    margin: 64px auto 0px auto;
    border-radius: 4px;
  }
  .menu-lang {
    display: block;
  }
}

.auth-container {
  background-color: #e5e5e5 !important;
  display: flex;
  flex-flow: column;
  min-height: 100%;
}

.auth-content {
  margin: auto;
  display: flex;
  width: 40%;
  margin-top: 64px;
  text-align:center;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 110px);
}

.session-ended-content {
  margin: auto;
  padding-top: 15%;
  width: 50%;
  margin-top: 64px;
}

.login-form-button {
  width: 300px;
}

.login-form-button:disabled {
  opacity: 0.7;
}
.auth-wrapper .alert-icon{
 position: unset;
 transform:unset;
 width: 40px;
 height: 40px;
}
.authAlert {
  max-width: 300px;
}