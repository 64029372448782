.container {
  color: #333333;
}
.reset-counter {
  color: #ffffff;
  border:0;
  background: transparent;
}


.encashment-warning {
  font-size: 12px !important;
}

.encashment-modal {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.encashment-alert {
  font-size: 12px;
}
.custom-input {
  margin-bottom: 25px;
  width: 200px;
}

.encashment-input-amount label {
  font-size: 14px !important;
}

.table-error {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 50px auto;
}

.table-error-button {
  margin-top: 50px;
}

.total-sum {
  display: flex;
  justify-content: space-between;
  padding: 6px;
  background: #333333;
  color: white;
  min-width: 50%;
  position: fixed;
  top: 91vh;
  /* bottom: 96px; */
  border-radius: 5px;
  user-select: none;
}

.failed-row {
  background: #f7e5e5;
}
.encashment-search {
  display: flex;
  justify-content: space-between;
}
.encashment-search .inputWrapper {
  min-width: 200px;
}

.encashment-input {
  width: 300px;
}

.pending-row {
  background: #ececec;
}
.logo-kassa24 {
  width: 128px;
  height: 40px;
}

.table-text-com {
  color: #999393;
  font-size: 10px;
  white-space: nowrap;
}
.service-type-block {
  background: #fff;
  border-radius: 2px;
  font-size: 12px;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #e9e9e9;
  margin: 2px;
  cursor: pointer;
}
.service-type-block:hover,
.service-block:hover {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  border-color: transparent;
}
.icon-bg {
  background: #03aeef;
  border-radius: 1000px;
  text-align: center;
  width: 50px;
  vertical-align: middle;
  height: 50px;
  display: inline-block;
  margin: 10px;
  background-size: 30px 30px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
}
.icon-bg img {
  height: 24px;
  margin-top: 10px;
}

.form-label {
  width: 33.3%;
}
.servicetype-title {
  display: inline-block;
  font-size: 14px;
  width: 60%;
  vertical-align: middle;
}

.search {
  width: 100%;
  height: 35px;
  text-indent: 10px;
  border-radius: 4px;
  border: 1px solid rgba(29, 30, 34, 0.2);
  background-image: url(../../public/images/icons/search.svg);
  background-repeat: no-repeat;
  background-position: right;
  z-index: 10;
  border-right: 0;
  padding-right: 35px;
  font-size:13px;
}

.MuiTableCell-paddingCheckbox {
  padding: 0 0 0 20px !important;
}
.material-checkbox-add {
  border: none;
}
.service-block-wrapper {
  width: 9%;
  display: flex;
}
.service-block {
  border:0;
  background: transparent;
  outline: none;
  float: left;
  max-height: 166px;
  margin: 2px;
  padding: 5px;
  max-width: 100%;
  border-radius: 2px;
  overflow: hidden;
}

.service-block-active,
.service-block:hover {
  border-radius: 8px;
}

.service-block-img {
  background-position: -13px 0;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  width: 100%;
  display: block;
  text-align: center;
}

.service-block-img img {
  padding: 5px;
  height: 65px;
  width: 95px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #fff;
}

.input-error-block {
  color: #ca4040;
}

.search-block-img {
  background-position: -13px 0;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  margin: 0 20px 0 10px;
  width: 5%;
  display: inline-block;
}

.search-block-img img {
  height: 25px;
  width: 25px;
  border: 1px solid #d1d1d1;
  border-radius: 20px;
}

.service-name {
  height: 100%;
  width: 100%;
  display: inline-block;
  margin: auto;
  line-height: 18px;
  text-align: center;
  word-break: break-word;
  font-size: 14px;
}
.pay-input {
  margin-top: 10px;
}

.pay-form {
  margin-top: 37px;
  display: flex;
  flex-flow: column;
}

.pay-form .form-group {
  display: flex;
  justify-content: space-between;
  /* flex-flow: column; */
  margin: 10px 0 0 0;
}

.btn-simple {
  background: linear-gradient(0deg, #FFDE16 0%, #FFF229 100%);
  font-size: 13px;
  color: rgba(58, 62, 70, 1);
  border: none;
  margin-top: 5px;
  padding-bottom: 9px;
  padding-top: 9px;
  text-transform: lowercase;
  cursor: pointer;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015) !important;
  width: 100px;
  border-radius: 4px;
}

.btn-primary,
.pay-form.btn.btn-primary {
  background: linear-gradient(0deg, #FFDE16 0%, #FFF229 100%);
  font-size: 16px;
  color: rgba(58, 62, 70, 1);
  border: none;
  margin-top: 20px;
  padding-bottom: 18px;
  padding-top: 18px;
  text-transform: lowercase;
  cursor: pointer;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015) !important;
  width: 100%;
  border-radius: 4px;
}

.btn-danger {
  font-size: 16px;
  color: rgba(58, 62, 70, 1);
  background-color: #03aeef;
  border: none;
  border-radius: 2px;
  margin-top: 20px;
  padding-bottom: 18px;
  padding-top: 18px;
  text-transform: lowercase;
  cursor: pointer;
  width: 30%;
}

.complex-table {
  border-collapse: collapse;
}

.complex-radio {
  display: "block";
  height: 30px;
  line-height: 30px;
}

.complex-table th,
.complex-table td {
  border: 1px solid #d1d1d0;
}

.text-center {
  text-align: center;
}

.receipt-cap-aes,
.receipt-cap {
  font-size: 12px;
  margin-top: 15px;
  display: flex;
}

.receipt-cap {
  flex-direction: column;
}

.receipt-cap p {
  margin: 5px 0;
}

.receipt-cap-aes .receipt-cap-col,
.receipt-cap-aes .receipt-cap-area {
  padding: 0 10px;
  flex: 1;
}

.receipt-cap-aes .receipt-cap-area {
  font-size: 10px;
}

.pay-form input:disabled,
.complex-table input:disabled {
  cursor: not-allowed;
  color: #919191;
}

hr {
  overflow: hidden;
}

.pay-form input,
.complex-table input {
  width: 100%;
  font-size: 14px;
  padding: 5px 0 5px 15px;
  border-radius: 2px;
  border: 1px solid #d1d1d0;
}

.pay-form .icon-center {
  display: flex;
  padding: 5px 0;
}

.pay-form svg {
  flex: 1;
}

.search-item {
  padding: 0 10px;
  cursor: pointer;
  z-index: 10;
}

.pay-form-input {
  display: flex;
  width: 66.6%;
  box-sizing: border-box;
  min-width: 66.6%;
}
.search-service-name {
  display: inline-block;
  width: 80%;
  word-break: break-all;
  padding-top: 15px;
  line-height: 15px;
  max-height: 50px;
  overflow-y: hidden;
}

.dark-backgroud {
  z-index: 2;
  position: fixed;
  background-color: #000;
  opacity: 0.4;
  -moz-opacity: 0.8;
  filter: alpha(opacity=80);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.confirm-info {
  padding-top: 20px;
}

.confirm-info p {
  font-size: 16px;
}

.large {
  width: 100% !important;
}

.pay-form p {
  font-size: 13px;
}

#react-autowhatever-1 {
  position: absolute;
  background-color: white;
  width: 100%;

  border-top: none;
  z-index: 10;
}
.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 52px;
  z-index: 2;
  border-left:1px solid #d1d1d0;
  border-right: 1px solid #d1d1d0;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  border-bottom: 1px solid #aaa;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-bottom: 1px solid #aaa;
  border-top: none;
  margin: 0;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #f7f7f7;
}

.bordered {
  border: 1px solid lightgray;
  padding: 10px;
}

.margin-top {
  margin: 20px 0 0 0;
}


.all-width {
  width: 100%;
}

.btn-primary:disabled,
.pay-form button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.text-left {
  text-align: left;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1031;
  padding-top: 150px;
}

.header-balance:hover {
  background-color: white;
}

.text-title > div {
  color: #919191;
  font-style: normal;
}

.balance-block-title div {
  margin-top: 15px;
  padding: 0 10px 5px 10px;
  line-height: 24px;
}

.balance-block-value div {
  height: 5px;
  line-height: 0px;
  padding: 0 10px;
}

.text-bordered > div:first-child {
  border-right: 0.5px solid #333333;
  padding-left: 25px;
}

.services-tab-block {
  background-color: #ffffff;
}

a {
  color: #333333;
}

a:hover {
  color: #ffcc05;
}

.service-list-block .ant-pagination {
  padding: 13px 20px;
  text-align: right;
  background-color: #f2f2f2;
}

.service-list-block .ant-pagination-prev,
.service-list-block .ant-pagination-jump-prev,
.service-list-block .ant-pagination-item {
  margin-right: 0;
}

.service-list-block .ant-pagination-item-active {
  border-color: #ffcc05 !important;
}

.pay-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.pay-title {
  display: flex;
  align-items: center;
}

.pay-service-name {
  margin: 0 auto;
}

.pay-block {
  padding: 28px 15px 15px 20px !important;
  background-color: #ffffff;
  min-height: 324px;
  width: 100%;
}

.footer-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  background: #828282;
  flex-flow: column-reverse;
  height: 45px;
  min-height: 45px;
  color: #fff;
  font-size: 12px;
  justify-content: center;
  position: absolute;
  bottom:0
}

.pay-block h2 {
  font-size: 20px;
  margin-bottom: 35px;
}

.pay-block h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  padding: 15px;
  word-break: break-word;
}

.pay-block h4 {
  margin-top: 15px;
  text-align: center;
}

.pay-logo {
  height: 60px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 105px;
}

.info-text {
  color: #4f4f4f;
  opacity: 0.8;
  font-size: 12px;
  line-height: 18px;
}


.service-block-active,
.service-block-active:focus,
.service-block:hover,
.btn-primary:after {
  background-color: #ffcc05 !important;
}

.service-block-active {
  border: none;
}

.group-block-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  overflow: auto;
}

.group-block-wrapper .service-block-wrapper {
  width: 16.6%;
}

.groups-modal {
  width: 60% !important;
  max-height: 70%;
}

.almaty-en-sb-modal {
  width: 90% !important;
  max-height: 60%;
}

.groups-modal .service-block {
  height: 166px;
}

.groups-modal .ant-modal-body {
  max-height: 400px;
  overflow-y: scroll;
}


.back-text {
  color: #1890ff;
  cursor: pointer;
}

.payment-method {
  padding: 10px 0 !important;
}

.header-search-tablet {
  display: none;
}

@media screen and (max-width: 1317px) {
  .service-block-img img {
    height: 100%;
    width: 100%;
  }

  .header {
    display: flex;
    background-color: #e5e5e5;
  }

  .header-search {
    display: none;
  }

  .header-search-tablet {
    display: block;
    margin: 80px 24px 0 24px;
    width: 100%;
  }


  .content {
    padding: 20px 24px 84px 24px !important;
  }

  .confirm-info .form-group label b {
    font-size: 11px;
  }
}

@media screen and (max-width: 1300px) {
  .service-block-img img {
    height: 100%;
    width: 100%;
  }
}

.payments-table {
  word-break: break-word;
  /* padding: 10px 12px 0 0; */
}

.favourites-info-text {
  padding-top: 4%;
  color: rgb(130, 130, 130);
  margin: 0 auto;
  font-size: 14px;
}

.favourite-icon-active {
  font-size: 22px;
  color: #ffcc05;
  cursor: pointer;
}

.favourite-icon-non-active {
  font-size: 22px;
  cursor: pointer;
}
.refresh-icon {
  margin-left: 10px;
  height: 18px;
  width: 18px;
  fill: #ffffff;
}

.status-wrapper {
  display: flex;
  align-items: center;
}
.circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: 7px;
}

.status-in-progress {
  background: #cccccc;
}

.status-success {
  background: green;
}

.status-failed {
  background: red;
}

.alert-form-group{
  flex-flow: column;
}

.alert-kaspi{
  margin-bottom: 10px;
}

.header-balance-wrapper {
  display: flex;
  justify-content: space-between;
}

.warning-block {
  font-size: 14px;
  background-color: rgb(255, 229, 143);
  color: black;
  padding: 15px;
  text-align: center;
}
.fiscal-block-button{
  height: 22px;
  margin-left: 12px;
  border:none;
border-radius: 3px;
}

.fiscal-status-success-title{
  color: #0B5E16;
  font-weight: bold;
  line-height: 140%;
  letter-spacing: -0.2px;
}

.fiscal-status-fail-title{
  font-weight: bold;
  line-height: 140%;
  letter-spacing: -0.2px;
  color: #A01F33;
}
.fiscal-block-fail{
  background: #E6C3C8 ;
}
.fiscal-block-success{
  background: #B8D9BD ;
}
.fiscal-block-pending{
  background-color: #C7C9CC;
}
.fiscal-block {
  font-size: 12px;
  color: black;
  padding: 5px 20px;
  text-align: left;
  margin-bottom: 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fiscal-block .status {
  float: right;
}

.fiscal-block .shift-opened {
  color: green;
}

.fiscal-block .shift-closed {
  color: red;
}
.fiscal-block
.fiscal-block .shift-pending {
  color: gray;
}

.form-select {
  width: 100%;
  position: relative;
  height: 32px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
}

.MuiTableCell-body button:nth-of-type(2) {
  margin-left: 1px;
}

.__react_component_tooltip {
  font-size: 12px !important;
  padding: 3px 7px !important;
}

.logo-block {
  outline: none;
}

.buttonWithoutStyles {
  border:0;
  background: transparent;
  text-align:inherit;
  font-family: inherit;
}
.version {
  color:white
}